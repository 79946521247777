import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaidIcon from '@mui/icons-material/Paid';
import PublicIcon from '@mui/icons-material/Public';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotesIcon from '@mui/icons-material/Notes';
import HotelIcon from '@mui/icons-material/Hotel';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CakeIcon from '@mui/icons-material/Cake';
import WcIcon from '@mui/icons-material/Wc';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const BookingInformation = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');

  const [booking, setBooking] = useState(null);

  const getBookingDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/bookings/${id}`, { headers: { token } });
    const data = await res.json();
    setBooking(data.data);
    console.log(data.data);
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  // Function to convert backend date to 'YYYY-MM-DD' format
  const convertDate = (backendDate) => {
    const dateObject = new Date(backendDate);
    const day = String(dateObject.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and add leading zero if needed
    const year = dateObject.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Return in 'DD-MM-YYYY' format
  };

  const formatDateForCreatedAt = (date) => {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    return d.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
          <Header title="Booking Details" subtitle={`Created at ${formatDateForCreatedAt(booking?.createdAt)}`} />

          <Box>
            <Link to={-1}>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: colors.blueAccent[800],
                  },
                }}
              >
                Go Back
              </Button>
            </Link>
          </Box>
        </Box>

        {booking && (
          <Box backgroundColor={colors.primary[400]} borderRadius={5} p="1.5rem">
            <Box display="flex" flexDirection="column" gap="10px">
              {/* booking Details */}
              <Box mb="2rem" display="flex" flexDirection="column" gap="10px">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Booking Details
                </Typography>

                <Box display="flex" gap="1rem" alignItems="start" flexDirection={{ xs: 'column', md: 'row' }}>
                  <Box>
                    <img src={booking.QRCodeUrl} maxWidth="200px" height="200px" alt="" />
                  </Box>
                  <Box flex={1} display="grid" gap="10px" width="100%">
                    <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <PublicIcon /> Destination
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.destination}
                        </Typography>
                      </Box>

                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <PublicIcon /> Start Destination
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.startDestination}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <CalendarTodayIcon /> Booking Date
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.bookingStartDate &&
                            `${convertDate(booking.bookingStartDate)} to ${convertDate(booking.bookingEndDate)}`}
                        </Typography>
                      </Box>

                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <DirectionsBusIcon /> Bus
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.bus}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <BookmarkIcon /> Contract No
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.contractNo}
                        </Typography>
                      </Box>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <PaidIcon /> Payment Status
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.paymentStatus ? 'Paid' : 'Not Paid'}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <HotelIcon /> Hotel
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.hotel}
                        </Typography>
                      </Box>
                      <Box
                        flex={1}
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <CorporateFareIcon /> Partner
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.partner}
                        </Typography>
                      </Box>
                    </Box>

                    {booking.notes && (
                      <Box
                        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                        border={1}
                        borderColor={colors.primary[300]}
                        borderRadius="8px"
                        p="10px"
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          display="flex"
                          alignItems="center"
                          gap="5px"
                        >
                          <NotesIcon /> Notes
                        </Typography>
                        <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                          {booking.notes}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              {/* Passenger Details */}
              <Box display="grid" gap="10px" mb="2rem">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Passenger Details
                </Typography>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Name
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerName}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Surname
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerSurname}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <MailOutlineIcon /> Email
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerEmail}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <LocalPhoneIcon /> Phone Number
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerPhone}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <WcIcon /> Gender
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerGender.trim()
                        ? booking.passengerGender === 'm'
                          ? 'Male'
                          : booking.passengerGender === 'f'
                          ? 'Female'
                          : 'Other'
                        : null}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <CakeIcon /> Date of Birth
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerDob && convertDate(booking.passengerDob)}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PlaceIcon /> Address
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerAddress}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <MenuBookIcon /> Passport
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.passengerPassport}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* guide Details */}
              <Box display="grid" gap="10px" mb="2rem">
                <Typography variant="h2" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
                  Guide Details
                </Typography>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Guide Name
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guideName}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <PersonIcon /> Guide Surname
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guideSurname}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <MailOutlineIcon /> Guide Email
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guideEmail}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <LocalPhoneIcon /> Guide Phone Number
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guidePhone}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" gap="10px" flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <BadgeIcon /> Guide License
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guideLicense}
                    </Typography>
                  </Box>

                  <Box
                    flex={1}
                    boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                    border={1}
                    borderColor={colors.primary[300]}
                    borderRadius="8px"
                    p="10px"
                  >
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <DriveEtaIcon /> Guide Vehicle No
                    </Typography>
                    <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                      {booking.guideVehicleNo}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  border={1}
                  borderColor={colors.primary[300]}
                  borderRadius="8px"
                  p="10px"
                >
                  <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
                    <PlaceIcon /> Guide Address
                  </Typography>
                  <Typography variant="h4" sx={{ color: colors.grey[100] }}>
                    {booking.guideAddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BookingInformation;
